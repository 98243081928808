import './styles.scss';

import B2BecPagination from 'components/B2becPagination';
import CustomButton from 'components/CustomButton';
import { DownloadIcon } from 'components/Icons';
import { NotificationManager } from 'components/Notification';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import {
  ASYNC_STATUS,
  PAGE_SIZE,
  SORT_FIELDS,
  SORT_ORDERS,
} from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { CUSTOMER_USER, USER_TYPES } from 'libs/constants/user';
import { isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { adminFapiUserManagement } from 'services/adminFapi';
import {
  getUserOverviewList,
  selectTotalUsers,
  selectUserList,
} from 'store/slices/usersManagementSlice';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import usePermission from '../../../hooks/usePermission';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../libs/constants/modulerights';
import { selectUserCountryId } from '../../../store/selectors/userSelector';
import FilterByLetter from './components/FilterByLetter';
import SearchUserInput from './components/Search';
import UserOverviewTable from './components/Table';
import Title from './components/Title';

const generateUserDetailUrl = (userId, isInvitation, userType) => {
  let dest = `/user-management/${userId}`;

  if (isInvitation) {
    dest = `/user-management/invitation/${userId}`;
  }
  if (userType && userType !== CUSTOMER_USER) {
    dest = `/user-management/${userType}/${userId}`;
  }

  return linkGenerator(dest);
};

const USER_TYPE_CODES = {
  [USER_TYPES.TECHNICAL_USER]: 'T',
  [USER_TYPES.EMPLOYEE_USER]: 'E',
  default: 'C',
};

const mapUserTypeToCode = (userType) => {
  return USER_TYPE_CODES[userType] || USER_TYPE_CODES.default;
};

const exportUsers = (userType) => {
  const mappedUserType = mapUserTypeToCode(userType);
  return adminFapiUserManagement.exportUserList(mappedUserType);
};

const UserManagementPage = (props) => {
  const { title, breadcrumbs, pageId, match } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  useAdobeAnalysis(null, {
    pageName: 'user management overview',
    pageId,
    pageType: PAGE_TYPES.USER_MANAGEMENT,
  });

  const totalUsers = useSelector(selectTotalUsers);
  const countryId = useSelector(selectUserCountryId);
  const userList = useSelector(selectUserList);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLetter, setSelectedLetter] = useState('');
  const [sortBy, setSortBy] = useState(SORT_FIELDS.lastName);
  const [orderBy, setOrderBy] = useState(SORT_ORDERS.ascend);
  const [searchTerm, setSearchTerm] = useState('');
  const userType = match?.params?.userType;
  const isEmployeePage = USER_TYPES.EMPLOYEE_USER === userType;
  const hideHeaders = isEmployeePage ? ['sapCustomerNumber', 'roles'] : [];
  const { hasPermission: hasReadUserPermission } = usePermission(
    MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER
  );

  const handleLetterClick = useCallback((chosenLetter) => {
    setSelectedLetter(chosenLetter);
    setCurrentPage(1);
  }, []);

  const handleSearchUsers = useCallback((searchQuery = '') => {
    setSearchTerm(searchQuery);
    setCurrentPage(1);
  }, []);

  const handleExportUsersError = useCallback(() => {
    NotificationManager.error({
      message: 'notification.error.export',
      variables: { type: 'users' },
    });
  }, []);

  const handleExportUsersSuccess = useCallback((response) => {
    if (response?.status === 200 && response?.data) {
      const a = document.createElement('a');
      const blob = new Blob([response.data], {
        type: 'text/csv',
      });
      const url = URL.createObjectURL(blob);

      a.setAttribute('href', url);
      a.setAttribute('download', `export_${Date.now()}.csv`);
      a.click();

      URL.revokeObjectURL(url);
    }
  }, []);

  const { execute: executeExportUsers, status: exportUsersStatus } = useAsync(
    exportUsers,
    false,
    handleExportUsersSuccess,
    handleExportUsersError
  );

  const handleExportUsers = useCallback(() => {
    return executeExportUsers(userType);
  }, [executeExportUsers, userType]);

  const handlePageChange = useCallback((current) => {
    setCurrentPage(current);
  }, []);

  const handleChangeSorters = useCallback((_pagination, _filters, sorter) => {
    const { field, order } = sorter;

    setSortBy(SORT_FIELDS[field]);
    setOrderBy(SORT_ORDERS[order]);
    setCurrentPage(1);
  }, []);

  const resetToInitialState = useCallback(() => {
    setCurrentPage(1);
    setSelectedLetter('');
    setSortBy(SORT_FIELDS.lastName);
    setOrderBy(SORT_ORDERS.ascend);
    setSearchTerm('');
  }, []);

  const handleOnCellClick = useCallback(
    (userId, isInvitation) => {
      history.push(generateUserDetailUrl(userId, isInvitation, userType));
    },
    [history, userType]
  );

  useEffect(() => {
    if (hasReadUserPermission) {
      dispatch(
        getUserOverviewList({
          current: currentPage,
          pageSize: PAGE_SIZE[10],
          sortBy,
          orderBy,
          searchTerm,
          selectedLetter,
          country: countryId,
          userType:
            USER_TYPES.TECHNICAL_USER === userType ? 'technicaluser' : userType,
        })
      );
    }
  }, [
    hasReadUserPermission,
    dispatch,
    currentPage,
    sortBy,
    orderBy,
    searchTerm,
    selectedLetter,
    countryId,
    userType,
  ]);

  return (
    <div className="user-management__container">
      <Title
        key={userType}
        userType={userType}
        setCurrentPage={setCurrentPage}
        resetToInitialState={resetToInitialState}
      />

      {/* search area */}
      <PermissionWrapper permission={MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER}>
        <div className="user-management__search">
          <SearchUserInput onSearchUsers={handleSearchUsers} />
        </div>
      </PermissionWrapper>
      {/* filter by alphabet */}
      <div className="user-management__filter-and-export-section">
        <PermissionWrapper permission={MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER}>
          <div className="user-management__filter-by-alphabet">
            <FilterByLetter
              onLetterClick={handleLetterClick}
              selectedLetter={selectedLetter}
            />
          </div>
        </PermissionWrapper>
        <PermissionWrapper
          permission={MODULE_USER_MANAGEMENT_RIGHTS.DOWNLOAD_USER}
        >
          <CustomButton
            className="user-management__export-users-button"
            onClick={handleExportUsers}
            disabled={
              isValidArray(userList) === false ||
              exportUsersStatus === ASYNC_STATUS.PENDING
            }
          >
            <DownloadIcon />
            {t('userManagement.exportUsers')}
          </CustomButton>
        </PermissionWrapper>
      </div>

      <PermissionWrapper permission={MODULE_USER_MANAGEMENT_RIGHTS.VIEW_USER}>
        <UserOverviewTable
          onChangeSorter={handleChangeSorters}
          onCellClick={handleOnCellClick}
          hideHeaders={hideHeaders}
          userList={userList}
        />

        <B2BecPagination
          total={totalUsers}
          current={currentPage}
          onPageChange={handlePageChange}
          pageSize={PAGE_SIZE[10]}
        />
      </PermissionWrapper>
    </div>
  );
};

UserManagementPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
};

UserManagementPage.defaultProps = {
  match: {},
};

export default React.memo(withRouter(UserManagementPage));
