import { createSelector } from '@reduxjs/toolkit';
import { selectUserLanguage } from 'store/selectors/userSelector';

import FuturetechLogoIcon from '../../assets/img/Futuretech_Logo.svg';
import KaercherLogoIcon from '../../assets/img/Kaercher_Logo_2015_CO_rgb-without-borders.svg';

export const selectIsCurrentTimeInSAPDowntime = (state) =>
  state.config.isCurrentTimeInSAPDowntime;
export const selectAvailableCountries = (state) =>
  state.config.availableCountries.data || [];
export const selectCountryName = createSelector(
  [selectAvailableCountries, (state, countryCode = '') => countryCode],
  (availableCountries, countryCode) =>
    availableCountries.find((country) => country?.countryId === countryCode)
      ?.countryName
);

export const selectServiceRepairPageByLanguage = createSelector(
  (state) => state.config.serviceRepairPage,
  selectUserLanguage,
  (serviceRepairPage, language) => serviceRepairPage[language]
);
export const selectIsCurrencyFront = (state) =>
  !!state.config.currencyConfig.currency_position_front;

export const selectCurrencySymbol = (state) =>
  state.config.currencyConfig.currency_symbol;

export const selectEquipmentManagerLink = (state) =>
  state.config.equipmentManagerLink;

export const selectLogo = createSelector(
  (state) => state.config?.logo,
  (logo) => {
    if (logo === 'futuretech') {
      return FuturetechLogoIcon;
    }
    return KaercherLogoIcon;
  }
);

export const selectRegions = createSelector(
  (state) => state.config.regionList,
  (regions) => {
    return regions.reduce((result, region) => {
      return {
        ...result,
        [region.id]: region,
      };
    }, {});
  }
);

export const selectDateFormat = (state) => state.config.dateFormat;

export const selectIsFuturetech = createSelector(
  (state) => state.config?.logo,
  (logo) => logo === 'futuretech'
);

export const selectHideQuickEntry = (state) => state.config.hideQuickEntry;

export const selectIsRentalAvailable = (state) => state.config.rentalAvailable;

export const selectShouldRenderServiceMenu = (state) =>
  state.config.shouldRenderServiceMenu;
