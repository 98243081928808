import { Col, Form, Input, Radio, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import AddressSelect from 'components/DeliveryAddressBlock';
import useRegion from 'hooks/useRegion';
import { validateZipCode } from 'libs/utils/validatorUtils';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDeliveryAddresses } from 'store/selectors/customerSelector';

const CustomAddressSelect = (props) => {
  const { id, onChange } = props;
  const [isNewAddress, setIsNewAddress] = React.useState(false);
  const [forceRender, setForceRender] = React.useState();
  const rawDeliveryAddresses = useSelector(getDeliveryAddresses);
  const { isShowRegion, regions } = useRegion();
  const addresses = useMemo(() => {
    return rawDeliveryAddresses.map(({ region, ...addr }) => {
      if (isShowRegion) {
        return {
          ...addr,
          region,
        };
      }
      return addr;
    });
  }, [rawDeliveryAddresses, isShowRegion]);
  const defaultCountry = addresses[0].country;
  const [t] = useTranslation();
  const handleSelectAddress = useCallback(
    (value) => {
      onChange(value);
      setIsNewAddress(false);
    },
    [onChange]
  );

  const handleToggleNewAddress = useCallback(() => {
    onChange({ country: defaultCountry });
    setForceRender(Math.random());
    setIsNewAddress(true);
  }, [onChange, defaultCountry]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <AddressSelect
          key={forceRender}
          placeholder={t('rental.modal.locationMachine')}
          deliveryAddresses={addresses}
          onChange={handleSelectAddress}
        />
      </Col>
      <Col span={24}>
        <Radio onClick={handleToggleNewAddress} checked={isNewAddress}>
          {t('form.label.newAddress')}
        </Radio>
      </Col>
      {isNewAddress && (
        <>
          <Col span={12}>
            <Form.Item
              name={[id, 'street']}
              label={t('form.label.street')}
              className="custom-form-item"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={[id, 'houseNumber']}
              label={t('form.label.houseNumber')}
              className="custom-form-item"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[id, 'street4']}
              label={t('form.label.street4')}
              className="custom-form-item"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} lg={{ span: 8 }}>
            <Form.Item
              name={[id, 'zip']}
              label={t('form.label.zip')}
              className="custom-form-item"
              rules={[
                { required: true },
                ({ getFieldValue }) => {
                  const country = getFieldValue([id, 'country']);
                  return validateZipCode(country);
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          {isShowRegion && (
            <Col span={24} lg={{ span: 8 }}>
              <Form.Item
                name={[id, 'region']}
                label={t('form.label.region')}
                className="custom-form-item"
                rules={[{ required: true }]}
              >
                <B2becCustomSelect
                  placeholder={t('form.placeHolder.region')}
                  options={regions}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24} lg={{ span: isShowRegion ? 8 : 12 }}>
            <Form.Item
              name={[id, 'city']}
              label={t('form.label.city')}
              className="custom-form-item"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={[id, 'country']}
              label={t('form.label.country')}
              className="custom-form-item"
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
};

export default CustomAddressSelect;
